dmx.Component('button', {

    extends: 'form-element',

    tag: 'button',

    render: function(node) {
        dmx.Component('form-element').prototype.render.call(this, node);
    }

});
